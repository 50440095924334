import React from 'react'
import Link from 'next/link'
import styles from './DashboardCard.module.css'

// Card componenet to display dashboard statistics for the admin pge
export default function DashboardCard ({ title, value, link, children}) {
  const buildLink = () => {
    if (!link) return

    return (
      <div className={styles['dash-card-footer']}>
        <Link href={link}>
          <a className={styles['dash-card-link']}>View All</a>
        </Link>
      </div>
    )
  }

  return (
    <div className={styles['dash-card']}>
      <div className={styles['dash-card-header']}>
        <p className={styles['dash-card-title']}>
          {title}
        </p>
      </div>
      <div className={styles['dash-card-body']}>
        <p className={styles['dash-card-value']}>
          {value == null ? children : value}
        </p>
      </div>
      { buildLink()}
    </div>
  )
}
