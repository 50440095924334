import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import { selectConfig } from "../configs/selectConfig"
import { toast } from 'react-toastify';

import MaskedInput from 'react-text-mask'
import Select from 'react-select'
import ValidationMessage from "./ValidationMessage"
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import ReactLoading from 'react-loading'

import { JobAPI, UserAPI, EmployerAPI } from "../lib/ladder" 
import ToastManager from "../lib/toastManager"
import UserManager from "../lib/userManager"
import styles from "./JobForm.module.css"
import DashboardCard from "./admin/dashboard/DashboardCard";
import CampaignInstruction from './CampaignInstruction'
import Billing from './Billing'

export default function CampaignModal({campaign, employerIdentifier, jobIdentifier, jobTitle, showModal, onShowModal}) {
  if (jobTitle === null || jobTitle === undefined) return(<></>)
  if (jobIdentifier === null || jobIdentifier === undefined) return(<></>)

  const authToken = UserManager.getAuthToken()

  const [name, setName] = useState(campaign ? campaign?.name : jobTitle)
  const [bidCents, setBidCents] = useState(campaign ? ((campaign?.bid_cents / 100) + '') : '1')
  const [budgetCents, setBudgetCents] = useState(campaign ? ((campaign?.monthly_budget_cents / 100) + '') : '500')
  const [status, setStatus] = useState(campaign ? campaign?.active : true)
  const [employer, setEmployer] = useState(null)

  const [instructionOpen, setInstructionOpen] = useState(false)
  const [showValidations, setShowValidations] = useState(false)

  useEffect(() => {
    setName(campaign ? campaign?.name : jobTitle)
    setBidCents(campaign ? ((campaign?.bid_cents / 100) + '') : '1')
    setBudgetCents(campaign ? ((campaign?.monthly_budget_cents / 100) + '') : '500')
    setStatus(campaign ? campaign?.active : true)
  }, [campaign, showModal, jobIdentifier, jobTitle])

  useEffect(() => { uploadEmployer() }, [])

  const uploadEmployer = () => {
    if (UserManager.isAdmin()) {
      uploadEmployerForAdmin()
    } else {
      uploadEmployerForEmployer()
    }
  }

  const uploadEmployerForAdmin = async () => {
    const res = await EmployerAPI.showAdmin(authToken, employerIdentifier)

    if (res.success) setEmployer(res.employer)
  }

  const uploadEmployerForEmployer = async () => {
    const res = await UserAPI.manage(authToken)
    
    if (res.success) setEmployer(res.employer)
  }

  if (
        name === undefined ||
        bidCents === undefined ||
        budgetCents === undefined ||
        status === undefined
      ) return <></>

  const statusOptions = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' }
  ]

  const dollarMaskForBidOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 3,
    allowNegative: false,
    allowLeadingZeroes: false,
  }

  const dollarMaskForBudgetOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 6,
    allowNegative: false,
    allowLeadingZeroes: false,
  }

  const validateCampaign = () => {
    let campaignErrors = []

    if(name.length === 0) campaignErrors.push("Campaign name can't be blank!");
    if(name.length > 100) campaignErrors.push("Campaign name can't be over 100 characters!");
    if(bidCents.length === 0 || (parseFloat(bidCents) < 0.5)) campaignErrors.push("Cost per click can't be blank!");
    if(budgetCents.length === 0 || (parseFloat(bidCents) < 0)) campaignErrors.push("Monthy budget can't be blank!");

    if(campaignErrors.length > 0) {
      setShowValidations(true)
      return false;
    } else {
      setShowValidations(false)
      return true;
    }
  }

  const collectData = () => {
    return { 
      campaign: {
        name,
        active: status,
        bid_cents: (parseFloat(bidCents.replace("$","")) * 100) || 0,
        monthly_budget_cents: (parseFloat(budgetCents.replace("$","")) * 100) || 0
      }
    };
  }

  const modifyCampaign = async () => {
    const authToken = UserManager.getAuthToken()
    const data = collectData()
    
    const res = await JobAPI.modifyCampaign(authToken, jobIdentifier, data)

    if(res.success && res.statusCode === 201) {
      toast.success("You have successfully created the campaign!")
      handleClose()
    } else if (res.success && res.statusCode === 200) {
      toast.success("You have successfully modified the campaign!")
      handleClose()
    } else {
      ToastManager.errorToast(res.errors.join(", "))
    }
  }

  const handleForm = async (e) => {
    e.preventDefault();
    if(!validateCampaign()) return;
    await modifyCampaign();
  }

  // TODO(INSTRUCTION)
  // const updateInstructionOpen = (instructionOpen) => setInstructionOpen(!instructionOpen)

  const handleClose = () => {
    setInstructionOpen(false)
    onShowModal(false)
  }

  if (!employer) {
    return(
      <Modal show={showModal} onHide={handleClose}>
        <div className="container" style={{minHeight: "65vh"}}>
          <div className={'row'}>
            <div className={'col-sm-12 col-md-4 mx-auto'}>
              <ReactLoading type="spin" width="90%" height="90%" color="#EE7B4F"/>
            </div>
          </div>
        </div>
      </Modal>
    )
  } else if (UserManager.isAdmin()) {
    return (
      <Modal show={showModal} onHide={handleClose}>
        {/* <CampaignInstruction
          updateInstructionOpen={updateInstructionOpen}
          instructionOpen={instructionOpen}
        /> */}
  
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontWeight: "bold",
              textAlign: "center",
              width: "100%",
              color: '#ff7f50'
            }}>{ campaign ? 'Edit' : 'Create Campaign for: '} <strong style={{color: '#000001'}}>{campaign ? campaign?.name : jobTitle}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{border: "none"}}>
                  <form onSubmit={(e) => {handleForm(e)}}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className={styles["form-group"]}>
                          <div className={styles["form-group-header"]}>
                            <p className={styles["form-label"]}>Campaign title<span className={"required-label"}>*</span></p>
                            <p className={styles["form-word-count"]}></p>
                          </div>
                          <input type="text" value={name} className="form-control" placeholder="Campaign name" onChange={(e) => setName(e.target.value)} maxLength={150}></input>
                          <ValidationMessage show={showValidations && (!name || name.length === 0)} message={"Campaign name can't be blank."}/>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className={styles["form-group"]}>
                          <p className={styles["form-label"]}>Monthly budget<span className={"required-label"}>*</span></p>
                          <div className="row no-gutters">
                            <div className="col-md-12">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="form-control input-group-text">$</span>
                                </div>
                                <MaskedInput
                                  value={budgetCents}
                                  mask={createNumberMask(dollarMaskForBudgetOptions)}
                                  inputMode={"numeric"}
                                  className="form-control"
                                  placeholder="Amount"
                                  onChange={(e) => setBudgetCents(e.target.value)}
                                />
                              </div>
                            </div>
                            <ValidationMessage
                              show={showValidations && (!budgetCents || budgetCents === null || budgetCents === undefined || budgetCents.length === 0 )}
                              message={"Need to have a monthly budget"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className={styles["form-group"]}>
                          <p className={styles["form-label"]}>Cost per Click<span className={"required-label"}>*</span></p>
                          <div className="row no-gutters">
                            <div className="col-md-12">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="form-control input-group-text">$</span>
                                </div>
                                <MaskedInput
                                  value={bidCents}
                                  mask={createNumberMask(dollarMaskForBidOptions)}
                                  inputMode={"numeric"}
                                  className="form-control"
                                  placeholder="Amount"
                                  onChange={(e) => setBidCents(e.target.value)}
                                />
                              </div>
                            </div>
                            <ValidationMessage
                              show={showValidations && (!bidCents || bidCents === null || bidCents === undefined || bidCents.length === 0 || parseFloat(bidCents) < 0.5 )}
                              message={"Cost per click can't be lower than $0.50 per click"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className={styles["form-group"]}>
                          <p className={styles["form-label"]}>Campaign Status</p>
                          <Select
                            instanceId={"select-item"}
                            options={statusOptions}
                            styles={selectConfig}
                            defaultValue={statusOptions.find(obj => obj['value'] === (campaign ? campaign?.active : true))}
                            placeholder={'Status'}
                            onChange={(option) => setStatus(option.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex">
                      <div className="col-6 d-flex">
                        <Button className="btn btn-primary btn-block flex-grow-1" onClick={(e) => handleForm(e)}>Save Campaign</Button>
                      </div>
                      <div className="col-6 d-flex">
                        <Button className="btn btn-secondary btn-block flex-grow-1" onClick={() => handleClose()}>Cancel</Button>
                      </div>
                    </div>
                  </form>
                  <hr />
                { campaign && (
                  <div className="row">
                    <div className="col-12">
                      <h1> Campaign performance for 
                        <b> { new Date().toLocaleString('en-US', { month: 'long', year: 'numeric' } ) } </b>
                      </h1>
                    </div>
  
                    <div className="col-12">
                      <DashboardCard
                        title={"Ad Impression Count"}
                        value={campaign.impressions}/>
                    </div>
                    <br />
                    <div className="col-12">
                      <DashboardCard
                        title={"Ad Click Count"}
                        value={campaign.clicks}/>
                    </div>
                    <br />
                    <div className="col-12">
                      <DashboardCard
                        title={"Amount Charged"}
                        value={'$' + campaign.unbilled_cents / 100}/>
                    </div>
                  </div> )
                }    
        </Modal.Body>
      </Modal>
    );
  } else if (employer.payment_methods.length < 1) {
    return(
      <Modal show={showModal} onHide={handleClose}>
        <div className="container" style={{minHeight: "65vh"}}>
          <div className={'row'}>
            <div className={'col-12'}>
            <Modal.Header closeButton>
                <Modal.Title
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "100%"
                  }}><strong>You need to have at least 1 payment type to use this feature</strong>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{border: "none"}}>
                <Billing
                  entity={employer}
                  uploadData={uploadEmployer}
                  showSubscriptionCards={false}
                  showPayments={false}
                />
              </Modal.Body>
            </div>
          </div>
        </div>
      </Modal>
    )
  } else {
    return (
      <Modal show={showModal} onHide={handleClose}>
        {/* <CampaignInstruction
          updateInstructionOpen={updateInstructionOpen}
          instructionOpen={instructionOpen}
        /> */}

        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontWeight: "bold",
              textAlign: "center",
              width: "100%",
              color: '#ff7f50'
            }}>{ campaign ? 'Edit' : 'Create Campaign for: '} <strong style={{color: '#000001'}}>{campaign ? campaign?.name : jobTitle}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{border: "none"}}>
                  <form onSubmit={(e) => {handleForm(e)}}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className={styles["form-group"]}>
                          <div className={styles["form-group-header"]}>
                            <p className={styles["form-label"]}>Campaign title<span className={"required-label"}>*</span></p>
                            <p className={styles["form-word-count"]}></p>
                          </div>
                          <input type="text" value={name} className="form-control" placeholder="Campaign name" onChange={(e) => setName(e.target.value)} maxLength={150}></input>
                          <ValidationMessage show={showValidations && (!name || name.length === 0)} message={"Campaign name can't be blank."}/>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className={styles["form-group"]}>
                          <p className={styles["form-label"]}>Monthly budget<span className={"required-label"}>*</span></p>
                          <div className="row no-gutters">
                            <div className="col-md-12">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="form-control input-group-text">$</span>
                                </div>
                                <MaskedInput
                                  value={budgetCents}
                                  mask={createNumberMask(dollarMaskForBudgetOptions)}
                                  inputMode={"numeric"}
                                  className="form-control"
                                  placeholder="Amount"
                                  onChange={(e) => setBudgetCents(e.target.value)}
                                />
                              </div>
                            </div>
                            <ValidationMessage
                              show={showValidations && (!budgetCents || budgetCents === null || budgetCents === undefined || budgetCents.length === 0 )}
                              message={"Need to have a monthly budget"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className={styles["form-group"]}>
                          <p className={styles["form-label"]}>Cost per Click<span className={"required-label"}>*</span></p>
                          <div className="row no-gutters">
                            <div className="col-md-12">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="form-control input-group-text">$</span>
                                </div>
                                <MaskedInput
                                  value={bidCents}
                                  mask={createNumberMask(dollarMaskForBidOptions)}
                                  inputMode={"numeric"}
                                  className="form-control"
                                  placeholder="Amount"
                                  onChange={(e) => setBidCents(e.target.value)}
                                />
                              </div>
                            </div>
                            <ValidationMessage
                              show={showValidations && (!bidCents || bidCents === null || bidCents === undefined || bidCents.length === 0 || parseFloat(bidCents) < 0.5 )}
                              message={"Cost per click can't be lower than $0.50 per click"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className={styles["form-group"]}>
                          <p className={styles["form-label"]}>Campaign Status</p>
                          <Select
                            instanceId={"select-item"}
                            options={statusOptions}
                            styles={selectConfig}
                            defaultValue={statusOptions.find(obj => obj['value'] === (campaign ? campaign?.active : true))}
                            placeholder={'Status'}
                            onChange={(option) => setStatus(option.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex">
                      <div className="col-6 d-flex">
                        <Button className="btn btn-primary btn-block flex-grow-1" onClick={(e) => handleForm(e)}>Save Campaign</Button>
                      </div>
                      <div className="col-6 d-flex">
                        <Button className="btn btn-secondary btn-block flex-grow-1" onClick={() => handleClose()}>Cancel</Button>
                      </div>
                    </div>
                  </form>
                  <hr />
                { campaign && (
                  <div className="row">
                    <div className="col-12">
                      <h1> Campaign performance for 
                        <b> { new Date().toLocaleString('en-US', { month: 'long', year: 'numeric' } ) } </b>
                      </h1>
                    </div>

                    <div className="col-12">
                      <DashboardCard
                        title={"Ad Impression Count"}
                        value={campaign.impressions}/>
                    </div>
                    <br />
                    <div className="col-12">
                      <DashboardCard
                        title={"Ad Click Count"}
                        value={campaign.clicks}/>
                    </div>
                    <br />
                    <div className="col-12">
                      <DashboardCard
                        title={"Amount Charged"}
                        value={'$' + campaign.unbilled_cents / 100}/>
                    </div>
                  </div> )
                }    
        </Modal.Body>
      </Modal>
    );
  }
}
